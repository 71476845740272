/**
 *
 * Asynchronously loads the component for Login
 *
 */

import React from "react";
import { lazyLoad } from "utils/loadable";
import { LoaderIcon } from "app/components/LoaderIcon";

export const Login = lazyLoad(
  () => import("./index"),
  (module) => module.Login,
  { fallback: <LoaderIcon /> }
);
