import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
 html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body, th, td, input, textarea, .btn, .dropdown-menu, .form-control {font-size: 13px;}

  body.fontLoaded {
    font-family: Lato, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  .btn-primary {
    background-color: #425cc7;
    border-color: #425cc7;
    font-weight: 500;
  }

  .btn-primary:hover {
    background-color: #546ccc;
    border-color: #546ccc;
    font-weight: 500;
  }

  .buttonAsLink,
.buttonAsLinkNoHover {
  margin: -2px 0 0 0;
  padding: 0;
  text-decoration: underline;
  border: 0;
  color: #425cc7;
  font-size: 14px;
  outline: 0;
  background-color: #fff;
}

.buttonAsLink:hover {
  text-decoration: none;
  background-color: #fff;
}

.buttonAsLinkDisabled {
  margin: -2px 0 0 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  outline: 0;
}

#invalid-email-warning {
  border-radius: 3px;
  background-color: #fbe8eb;
  color: #e11f3b;
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  padding-left: 7px;
  line-height: 50px;
  font-size: 16px;
}

`;
