/**
 *
 * LoaderIcon
 *
 */
import React from "react";
import Loader from "react-loader-advanced";
import Spinner from "react-spinkit";

interface Props {}

export function LoaderIcon() {
  const spinnerColor = "#1F469A";
  return (
    <div style={{ margin: "auto" }}>
      <Loader
        show
        message={
          <span>
            <p
              className="d-inline-block"
              style={{
                fontSize: "20px",
                color: "black",
                marginRight: "14px",
              }}
            >
              Please wait
            </p>
            <Spinner name="three-bounce" color={spinnerColor} />
          </span>
        }
        contentBlur={30}
        backgroundStyle={{
          backgroundColor: "rgba( 0 0 0 0)",
          position: "relative",
          paddingBottom: "25px",
          paddingTop: "25px",
        }}
      />
    </div>
  );
}
