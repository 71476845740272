/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";

import { Login } from "./containers/Login/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";

export function App() {
  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - Criteria Corp." defaultTitle="Criteria Corp.">
        <meta name="description" content="A Criteria Corp. application" />
      </Helmet>

      <Switch>
        <Route exact path="/" component={Login} />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
